
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/admin/login",
      function () {
        return require("private-next-pages/admin/login.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/admin/login"])
      });
    }
  