import Head from "next/head";
import React, { useState, useEffect } from "react";
import LoginTemplate from "@/templates/LoginTemplate";
import { userSession, userLogin, getUserById, adminLoginRedirect } from "services/user.service";
import {
  getAuth,
  getCompanyId,
  setAuth,
  setCompanyId,
  setCompanyTimeZone,
} from "services/identity.service";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "yup-phone";
import { yupValidator } from "lib/yup-validator";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { getSignedUrl } from "services/aws-uploader.service";
import { roleDefaultPage } from "lib/utils";
import APIResponseToast, { useToast } from "molecules/APIResponseToast";

const LoginIndex = () => {
  const router = useRouter();
  const { toast, showToastByResponse } = useToast();
  const yupValidator = (schema) =>
    async (data, context) => {
      try {
        const values = { ...data, loginType: context.parent.loginType };
        await schema.validate(values, { abortEarly: false });
        return { isValid: true };
      } catch (errors) {
        return { isValid: false, errors };
      }
    };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupValidator(yup.object().shape(
      {
        mobileNumber: yup.string().when('loginType', {
          is: "mobileNumber",
          then: yup.string().phone(null, null, "Please enter a valid phone number").required('A phone number is required'),
          otherwise: yup.string().nullable().notRequired()
        }),
        loginType: yup.string(),
        emailId: yup.string().when('loginType', {
          is: "email",
          then: yup.string().email("Please enter a valid e-mail").max(255).required("Email is required"),
          otherwise: yup.string().nullable().notRequired()
        }),
      }
    )),defaultValues:{
      loginType: "mobileNumber"
    }
  });
  const countryOptionsList = [];
  getCountries().forEach((country) => {
    countryOptionsList.push({
      value: country,
      label: en[country] + " +" + getCountryCallingCode(country),
      countryCode: getCountryCallingCode(country),
    });
  });
  const [country, setCountry] = useState({
    value: "IN",
    label: "+91",
    countryCode: 91,
  });
  const handleCountryChange = (country) => {
    const val = "+" + getCountryCallingCode(country.value);
    setCountry({
      value: country.val,
      label: val,
      countryCode: parseInt(getCountryCallingCode(country.value)),
    });
  };

  const [apiError, setApiError] = useState([]);

  const [loginDetails, setLoginDetails] = useState({
    step: 1,
    mobileNumber: "",
    countryCode: 91,
    emailId: "",
    loginType: "",
  });

  const onClickBackArrow = () => {
    setLoginDetails({
      ...loginDetails,
      step: 0
    })
  }

  const handleLoginOptionSelect = (type) => {
    setValue('loginType', type)
    setLoginDetails({
      ...loginDetails,
      step: 1
    })
  }
  const handleGetOTP = async (data) => {
    const { loginType, mobileNumber, emailId } = getValues();
    const res = await userLogin(mobileNumber, country.countryCode, loginType, emailId ? emailId.toLowerCase() : emailId);
    if (res.status) {
      setLoginDetails({
        step: 2,
        mobileNumber: mobileNumber,
        emailId: emailId ? emailId.toLowerCase() : emailId,
        loginType: loginType,
        countryCode: country.countryCode,
      });
      setApiError([]);
    } else {
      setApiError(res.message);
    }
  };

  const handleLogin = async (otp) => {
    if (otp.length == 4) {
      const res = await userSession(
        loginDetails.mobileNumber,
        loginDetails.emailId,
        loginDetails.loginType,
        otp,
        loginDetails.countryCode
      );
      if (res.status) {
        setAuth({
          id: res.entity.id,
          token: res.entity.token,
          refreshToken: res.entity.refreshToken,
        });
        const privileges = res.entity?.roles.reduce((privileges, role) => {
          role.privileges?.forEach(
            (privilege) => (privileges[privilege.slug] = true)
          );
          return privileges;
        }, {});
        if (privileges.organization_management) {
          setCompanyId(null);
          router.push("/superadmin");
        } else if (res.entity?.company.length == 1) {
          setCompanyId(res.entity?.company[0].id);
          setCompanyTimeZone(res.entity?.company[0].timeZone);
          const company = res.entity.company[0];
          const routerPathObj = roleDefaultPage(res.entity?.roles, res.entity, company);
          router.replace(routerPathObj);

        } else {
          for (const element of res.entity.company) {
            element.logo =
              element.logo != "" ? await getSignedUrl(element.logo) : "";
          }
          setCompanyList(res.entity?.company);
          setLoginDetails({
            ...loginDetails,
            step: 3,
          });
        }
      } else {
        setApiError(res.message);
      }
    }
  };

  const resendOtp = async () => {
    const { loginType, emailId } = getValues();
    const res = await userLogin(loginDetails.mobileNumber, loginDetails.countryCode, loginType, emailId);
    
    if (!res.status) {
      showToastByResponse({response : res})
    }
  };

  const editMobile = () => {
    setApiError([]);
    setLoginDetails((prevState) => {
      return { ...prevState, step: 1 };
    });
  };

  const [companyList, setCompanyList] = useState([]);
  const onCompanySelect = async (company) => {
    setCompanyId(company.id);
    setCompanyTimeZone(company.timeZone);
    const auth = getAuth();
    const userDetails = await getUserById(auth.id);
    const companyId = getCompanyId();
    const currentCompany = userDetails.entity.company.find((company) => company.id === companyId)
    const slug = currentCompany.slug;
    const roles = userDetails.entity.company.find(item => item.id === company.id).userRoles.map(item => item.Role)
    const routerPathObj = roleDefaultPage(roles, userDetails, currentCompany)
    router.replace(routerPathObj);
  };

  useEffect(() => {
    adminLoginRedirect(router)
  }, []);


  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <APIResponseToast toast={toast} />
      <LoginTemplate
        // login
        handleSubmit={handleSubmit}
        control={control}
        register={register}
        country={country}
        handleCountryChange={handleCountryChange}
        countryOptionsList={countryOptionsList}
        setError={setApiError}
        handleLoginOptionSelect={handleLoginOptionSelect}
        getValues={getValues}
        setValue={setValue}
        isAdmin={true}
        //
        loginDetails={loginDetails}
        apiError={apiError}
        handleGetOTP={handleGetOTP}
        handleLogin={handleLogin}
        resendOtp={resendOtp}
        editMobile={editMobile}
        companyList={companyList}
        onCompanySelect={onCompanySelect}
        onClickBackArrow={onClickBackArrow}
      />
    </>
  );
};
export default LoginIndex;